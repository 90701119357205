import request from '../utils/request'

  //獲取影片列表
export function getFilms(page, count) {
    return request({
        method: 'GET',
        url: `/films?page=${page}&count=${count}&sort=browse_count&sort_direction=desc`,
      })
  }

  //獲取所有影片列表
  export function getAllFilms() {
    return request({
        method: 'GET',
        url: `/films?sort=browse_count&sort_direction=desc`,
      })
  }

  //獲取影片詳情
  export function getFilmsDetail(flimId) {
    return request({
        method: 'GET',
        url: `/films/${flimId}`,
      })
  }
  //獲取搜索的影片列表
  export function getFilmsList(params) {
    return request({
        method: 'GET',
        url: `/films?sort=browse_count&sort_direction=desc${params}`,
      })
  }

export function countFilmsByCategory() {
  return request({
    method: 'GET',
    url: '/films/count-by-category'
  })
}