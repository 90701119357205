<template>
  <div class="films-detail-bg">
    <common-banner
      :bannerTip="$t('OnlineCourses.tip2')"
      :bannerImg="require('../../assets/images/inspire-banner.webp')"
      :bannerTitle1="$t('SportsFilms.tip1')"
      :bannerTitle2="$t('SportsFilms.title')"
      colorType="orange"
    />
    <el-breadcrumb separator-class="el-icon-arrow-right" class='breadcrumb'>
      <el-breadcrumb-item :to="{ name: 'Inspire' }">{{ $t('Home.nav2') }}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'SportsFilms' }">{{ $t('SportsFilmsDetail.nav') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ filmData.title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="sports-title" v-if="filmData">
      <div class="left">
        <p class="line-left1"></p>
        <p class="line-left2"></p>
      </div>
      <p>{{ filmData.title }}</p>
      <div class="right">
        <p class="line-right1"></p>
        <p class="line-right2"></p>
      </div>
    </div>
    <iframe
      ref="iframe"
      v-if="filmData"
      :width="screenWidth > 480 ? '74%' : '90%'"
      :height="screenWidth > 480 ? '600' : '190'"
      :src="filmData.path"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <!-- <div class="seach">
      <p>{{ $t('SportsFilmsDetail.seach') }}</p>
    </div> -->
    <div class="bottom" v-if="filmData">
      <div class="left height-con">
        <div class="top-left-con">
          <div class="content">
            <p class="title">
              <!-- <span>{{ $t('SportsFilmsDetail.title') }}</span> -->
              <span style='color: white;'>{{ filmData.title || "無" }}</span>
            </p>
            <p class="title-tips">
              <span class="tips">{{ $t("SportsFilmsDetail.target") }}</span>
              <span>{{ filmData.target || "無" }}</span>
            </p>
          </div>
        </div>
        <div class="bottom-left-con">
          <div class="play">
            <p class="title">{{ $t("SportsFilmsDetail.play_title") }}</p>
            <div class="title-tips">
              <ol>
                <li v-for='line, i in filmData.play' :key='i'> 
                  {{ line }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="right height-con">
        <div class="top-right-con">
          <div class="active">
            <p class="title">{{ $t("SportsFilmsDetail.Activity_summary") }}</p>
            <p class="title-tips">
              <ol>
                <li v-for='line, i in filmData.summary' :key='i'> 
                  {{ line || "無" }}
                </li>
              </ol>
            </p>
          </div>
        </div>
        <div class="bottom-right-con">
          <div class="advice">
            <p class="title">{{ $t("SportsFilmsDetail.advise") }}</p>
            <p class="title-tips">
              <ol>
                <li v-for='line, i in filmData.advice' :key='i'> 
                  {{ line }}
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFilmsDetail } from "@/api/videos";
import CommonBanner from '@/components/CommonBanner'
export default {
  components: {
    CommonBanner,
  },
  data() {
    return {
      filmData: {
        play: []
      },
      screenWidth: window.document.body.clientWidth,
      number: 5,
      txt_list: [
        "play_one",
        "play_two",
        "play_three",
        "play_four",
        "play_five",
      ],
      sum_list: ["summary_txt1", "summary_txt2"],
      advise_list: ["tip1", "tip2"],
    };
  },
  methods: {
    formatText() {
      let play = [], summary = [], advice = []
      if(this.filmData.plate1_content) {
        let lines = this.filmData.plate1_content.split('\n')
        let n = 0
        for(let i=0; i<lines.length; i++) {
          let temp = lines[i].split('.')
          if(!isNaN(temp[0]) && lines[i]!='') {
            play.push(lines[i].substring(temp[0].length+1).trimStart())
            n++
          }
          else {
            if(lines[i]=='') {
              continue
            }
            if(i!=0) {
              play[n-1]+='\n'+lines[i]
            }
            else {
              play.push(lines[i])
              n++
            }
          }
        }
        this.filmData.play = play
      }
      else {
        this.filmData.play = ['無']
      }
      if(this.filmData.plate2_content) {
        let lines = this.filmData.plate2_content.split('\n')
        console.log(lines)
        let n = 0
        for(let i=0; i<lines.length; i++) {
          let temp = lines[i].split('.')
          if(!isNaN(temp[0]) && lines[i]!='') {
            summary.push(lines[i].substring(temp[0].length+1).trimStart())
            n++
          }
          else {
            if(lines[i]=='') {
              continue
            }
            if(i!=0) {
              summary[n-1]+='\n'+lines[i]
            }
            else {
              summary.push(lines[i])
              n++
            }
          }
        }
        this.filmData.summary = summary
        console.log(this.filmData.summary)
      }
      else {
        this.filmData.summary = ['無']
      }
      if(this.filmData.plate3_content) {
        let lines = this.filmData.plate3_content.split('\n')
        let n = 0
        for(let i=0; i<lines.length; i++) {
          let temp = lines[i].split('.')
          if(!isNaN(temp[0]) && lines[i]!='') {
            advice.push(lines[i].substring(temp[0].length+1).trimStart())
            n++
          }
          else {
            if(lines[i]=='') {
              continue
            }
            if(i!=0) {
              advice[n-1]+='\n'+lines[i]
            }
            else {
              advice.push(lines[i])
              n++
            }
          }
        }
        this.filmData.advice = advice
      }
      else {
        this.filmData.advice = ['無']
      }
    },
    getFilmsDetail(filmId) {
      getFilmsDetail(filmId).then((res) => {
        this.filmData = res.data;
        this.formatText()
      });
    },
  },
  created() {
    this.getFilmsDetail(this.$route.params.filmId);
  },
};
</script>
<style lang="scss">
ol {
  list-style-type: decimal;
}
.breadcrumb {
  margin-top: 3%;
  .el-breadcrumb__separator[class*=icon] {
    font-size: 26px;
  }
  .el-breadcrumb__item {
    &:last-child {
      .el-breadcrumb__inner {
        color: #0e85d4;
      }
    }
    .el-breadcrumb__inner {
      font-size: 26px;
    }
  }
}
.films-detail-bg {
  .sports-title {
    margin: 4% auto;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #28327b;
    font-size: 32px;
    font-weight: bold;
    // font-family: "NotoSansCJKtc-Bold";
    transform: skew(-10deg);
    overflow: visible;
    .left {
      width: 60px;
      height: 50px;
      position: relative;
      .line-left1 {
        width: 30px;
        height: 2px;
        background: #28327b;
        position: absolute;
        top: 18px;
        left: 12px;
      }
      .line-left2 {
        width: 30px;
        height: 2px;
        background: #28327b;
        position: absolute;
        top: 26px;
        left: 0px;
      }
    }
    .right {
      width: 60px;
      height: 50px;
      position: relative;
      .line-right1 {
        width: 40px;
        height: 2px;
        background: #28327b;
        position: absolute;
        top: 18px;
        right: 0px;
      }
      .line-right2 {
        width: 20px;
        height: 2px;
        background: #28327b;
        position: absolute;
        top: 26px;
        right: 18px;
      }
    }
  }
  .seach {
    margin-top: 2%;
  }
  .bottom {
    margin: 4% 0;
    display: flex;
    .title {
      font-weight: bold;
      font-size: 30px !important;
      margin-bottom: 15px !important;
      color: #28327b;
    }
    .title-tips {
      font-size: 20px !important;
      min-height: 110px;
      word-break: break-word;
    }
    .left {
      min-width: 400px;
      width: 50%;
      transform: skew(-10deg) translateX(-6%);
      .top-left-con {
        width: 106%;
        min-height: 60px;
        background: #28327b;
        display: flex;
        //justify-content: center;
        align-items: center;
        padding: 20px 0;
        .content {
          width: 65%;
          min-height: 50px;
          color: #fff;
          text-align: left;
          margin-left: 18%;
          padding: 20px 0;
          transform: skew(10deg);
          display: inline-block;
        }
      }
      .bottom-left-con {
        width: 106%;
        min-height: 276px;
        background: #f2f2f2;
        margin-top: 15px;
        display: flex;
        padding: 32px 0px;
        .play {
          width: 60%;
          text-align: left;
          margin-left: 22%;
          transform: skew(10deg);
          display: inline-block;
          p:nth-child(1) {
            margin-bottom: 2%;
            font-size: 24px;
          }
          p:nth-child(2) {
            // font-family: "MHeiHK-Medium";
            white-space: pre-line;
          }
        }
      }
    }
    .right {
      width: 50%;
      transform: skew(-10deg) translateX(2%);
      .top-right-con {
        width: 104%;
        min-height: 210px;
        margin-bottom: 15px;
        padding: 20px 0;
        background: #f15922;
        //transform: skew(-10deg) translateX(4%);
        display: flex;
        justify-content: center;
        align-items: center;
        .active {
          width: 60%;
          text-align: left;
          transform: skew(10deg) scale(0.9);
          p:nth-child(1) {
            font-size: 24px;
            margin-bottom: 2%;
          }
          p:nth-child(2) {
            // font-family: "MHeiHK-Medium";
            white-space: pre-line;
          }
        }
      }
      .bottom-right-con {
        width: 104%;
        min-height: 228px;
        padding: 20px 0;
        background: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        .advice {
          width: 60%;
          min-height: 300px;
          text-align: left;
          transform: skew(10deg) scale(0.9);
          p:nth-child(1) {
            font-size: 24px;
            margin-bottom: 2%;
          }
          p:nth-child(2) {
            // font-family: "MHeiHK-Medium";
            white-space: pre-line;
          }
        }
      }
    }
    .height-con {
      min-height: 400px;
    }
  }
}
@media screen and (max-width: 480px) {
  .breadcrumb {
    padding: 50px 50px 0 50px;
    margin: 0;
    .el-breadcrumb__separator[class*=icon] {
      font-size: 25px;
    }
    .el-breadcrumb__inner {
      font-size: 25px;
    }
  }
  .films-detail-bg {
    iframe {
      width: 100%;
      padding: 0 50px;
      box-sizing: border-box;
    }
    .sports-title {
      margin: 40px 0;
      p {
        font-size: 32px;
      }
      .left {
        .line-left1 {
          width: 29px;
          top: 21px;
          left: 19px;
        }
        .line-left2 {
          width: 29px;
          top: 28px;
          left: 11px;
        }
      }
      .right {
        .line-right1 {
          width: 35px;
          top: 21px;
          right: 17px;
        }
        .line-right2 {
          width: 20px;
          top: 28px;
          right: 32px;
        }
      }
    }
    .bottom {
      flex-direction: column;
      .left {
        width: auto;
        transform: none;
        .top-left-con {
          width: 100%;
          justify-content: center;
          .content {
            transform: none;
            margin-left: 0;
            width: 80%;
            .title {
              font-size: 32px !important;
            }
            .title-tips {
              font-size: 24px !important;
            }
          }
        }
        .bottom-left-con {
          width: 100%;
          margin: 20px 0;
          justify-content: center;
          .play {
            width: 80%;
            transform: none;
            margin-left: 0;
            p:nth-child(1) {
              font-size: 32px !important;
            }
            p:nth-child(2) {
              font-size: 24px !important;
            }
          }
        }
      }
      .right {
        width: 108%;
        min-height: 200px;
        display: flex;
        transform: skew(-10deg) translateX(-2%);
        .top-right-con {
          width: 50%;
          transform: translateX(-5%);
          .active {
            p:nth-child(1) {
              font-size: 32px !important;
            }
            p:nth-child(2) {
              font-size: 24px !important;
            }
          }
        }
        .bottom-right-con {
          width: 50%;
          .advice {
            width: 80%;
            p:nth-child(1) {
              font-size: 16px;
            }
            p:nth-child(2) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
